import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M15.47 11.302h3.757c1.861 0 2.773-.828 2.773-2.549V4.54C22 2.828 21.097 2 19.227 2H15.47c-1.86 0-2.772.828-2.772 2.54v4.223c0 1.711.903 2.54 2.772 2.54M14.092 4.54c0-.791.13-1.145 1.377-1.145h3.758c1.246 0 1.377.345 1.377 1.145v4.213c0 .791-.13 1.145-1.377 1.145H15.47c-1.247 0-1.377-.345-1.377-1.145zM15.47 22h3.757C21.089 22 22 21.088 22 19.228V15.47c0-1.86-.903-2.772-2.773-2.772H15.47c-1.86 0-2.772.902-2.772 2.772v3.758c0 1.86.903 2.772 2.772 2.772m-1.377-6.53c0-1.098.279-1.377 1.377-1.377h3.758c1.098 0 1.377.28 1.377 1.377v3.758c0 1.098-.28 1.377-1.377 1.377H15.47c-1.098 0-1.377-.28-1.377-1.377zM4.772 11.302H8.53c1.86 0 2.772-.828 2.772-2.549V4.54C11.302 2.828 10.4 2 8.53 2H4.772C2.912 2 2 2.828 2 4.54v4.223c0 1.711.902 2.54 2.772 2.54M3.395 4.54c0-.791.13-1.145 1.377-1.145H8.53c1.247 0 1.377.345 1.377 1.145v4.213c0 .791-.13 1.145-1.377 1.145H4.772c-1.246 0-1.377-.345-1.377-1.145zM4.772 22H8.53c1.86 0 2.772-.912 2.772-2.772V15.47c0-1.86-.902-2.772-2.772-2.772H4.772C2.912 12.698 2 13.6 2 15.47v3.758C2 21.088 2.902 22 4.772 22m-1.377-6.53c0-1.098.28-1.377 1.377-1.377H8.53c1.098 0 1.377.28 1.377 1.377v3.758c0 1.098-.279 1.377-1.377 1.377H4.772c-1.097 0-1.377-.28-1.377-1.377z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }